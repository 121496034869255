import React, { Component } from "react";
// import { Link, navigate } from 'gatsby'
import Header from "./header"
// import OurTeam from "../../images/our-team.png";
import EnterpriseSoftware from "../../images/enterprise-software.png";
import DriverTablets from "../../images/driver-tablets.png";
import DispatchingSystem from "../../images/dispatching-system.png";
import Sensors from "../../images/sensors.png";
import Live from "../../images/live-eta.png";

class Technologypage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <Header />

              <div className="terms">
              <div className="termscondition">
                <h4>Terms and Conditions</h4>
                <ol>
                  <li>Containers that exceed the agreed upon tonnage limit will be charged a price per ton (plus tax) over that limit.</li>
                  <li>You will be charged for all unsuccessful attempts to deliver or pick up containers or customer requested relocations.</li>
                  <li>All C.O.D. accounts must pay for any past due charges owed in advance of delivery.</li>
                  <li>
                    Credit Cards are required for C.O.D. customers to place orders. We may accept payment by check and hold the credit card as backup against returned checks or unpaid balances more than 30 days old. By accepting the container you grant Curbside Inc. the authority to charge your credit card for all applicable fees and taxes and you will be notified by mail/email of those charges.
                  </li>
                  <li>We will not allow our drivers to sit on-site while containers are being loaded without prior approval. Any wait time will be charged at an agreed upon rate before delivery of the container.</li>
                  <li>Live load orders require a specified minimum amount of material to be picked up based on distance and material type at an agreed upon rate before the pick up occurs. Upon pick up, if there is less material than the minimum, the customer is still responsible to pay the minimum charge agreed upon for that pick up.</li>
                  <li>Containers are to be filled level with the top, with NO debris sticking out of the top or sides of the container. Any overload will be charged to your account and you will be notified by mail/email of those charges.</li>
                  <li>Prices are charged based on material type and container size provided by the customer. If upon removal the material inside of the container differs from what was originally indicated by the customer at the time of the order, the customer will be responsible for paying any up-charges associated with the actual material disposed of.</li>
                  <li>We are not responsible for damage to placement surfaces. The customer is to provide adequate planking or suitable supports between the container and placement surface. Customers assume all responsibility for any damages resulting from the container or truck.</li>
                  <li>Customer is responsible for all parking and construction violations.</li>
                  <li>You will be charged for all additional renewals of the permit (after the 5 day expiration period), unless we are otherwise notified by you to pick up the container from the premises.</li>
                  <li>Containers are not to be moved by customers. Any damage caused to our containers could result in damage claims or repair charges.</li>
                </ol>
                <h5><span>Unacceptable Items:</span> The following items cannot be loaded into your container: </h5>
                <ul>
                  <li><span>NO</span> 55 gallon drums empty or full</li>
                  <li><span>NO</span> car batteries</li>
                  <li><span>NO</span> asbestos or asbestos containing products</li>
                  <li><span>NO</span> Hazardous Materials (liquids such as paint, oil, solvent, pesticides, etc.)</li>
                  <li><span>NO</span> CFC containing appliances (such as air conditioners, refrigerators, freezers, de-humidifiers, etc.)</li>
                  <li><span>NO</span> Car Tires</li>
                </ul>
              </div>
              </div>
            </div>

    );
  }
}

export default Technologypage;
