import React from "react"
import Layout from "../components/layout"
import Terms from "../components/marketing/terms"
import SEO from "../components/seo"

const TechnologyPage = () => (
  <Layout>
    <SEO title="Our Technology" />
    <Terms/>
  </Layout>
)

export default TechnologyPage
